import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import toast from "react-hot-toast";

export interface DepartmentsListData {
    data:{
        id: string,
        type: string,
        message?: string,
        attributes: {
          department_name: string,
          description: string,
          domains_for_department: string,
          department_head: string,
          prime_user: string,
          members?:MembersList[]
        }

    }
}

export interface DepartmentData {
  department_name :  string,
  domains_for_department : string,
  department_description : string,
  department_head : string,
  prime_user : string,
  id : string
}

interface AllAgentsResponseJson {
  data: Array<AgentType>;
}

export interface AgentType{
    id: string,
    type: string,
    attributes: {
        full_name: string,
        email: string,
        title: string,
        work_phone: null,
        mobile_phone: null,
        secondary_email: string,
        location: string,
        department_id: null | string,
        reporting_manager: string,
        address: string,
        time_zone: string,
        time_format: string,
        language: string,
        level: string,
        background_information: string,
        department: {
            id: null | string,
            name: null | string
        }
    }
        
}

interface DepartmentResponseJson {
  data: DepartmentsListData[];
}

interface ErrorRes {
  errors:[
    {
      token: string;
    }
  ]
}

export type SelectedAgent = {
    id:string,
    type:string,
    value:string
}

export type AddMemeberArrayPayload = {
  agent_id: string,
  agent_type: string,
}

type FieldName = "department_name" | "domains_for_department" | "description" | "department_head" | "prime_user"

interface MembersList {
  address?: string,
  agent_type?: string,
  background_information?: string,
  company_id?: number,
  created_at?: string,
  department_id?: null | string,
  email?: string,
  full_name?: string,
  id?: number | string,
  language?: string,
  level?: string,
  location?: string,
  mobile_phone?: null | string,
  password_digest?: string,
  reporting_manager?: string,
  secondary_email?: string,
  time_format?: string,
  time_zone?: string,
  title?: string,
  updated_at?: string,
  user_type?: string,
  work_phone?: null | string,
  type?:string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showModal:boolean;
  isLoading:boolean;
  departmentDetails:{
    department_name: string,
    description: string,
    domains_for_department: string,
    department_head: string,
    prime_user: string,
    members?:MembersList[]
    },
  editDetailsForm:{
    department_name: string,
    description: string,
    domains_for_department: string,
    department_head: string,
    prime_user: string,
    members?:MembersList[]
    },
    editFormErrors:{
        department_name: boolean,
        description: boolean,
        domains_for_department: boolean,
        department_head: boolean,
        prime_user: boolean
    }
    departmentTouched:boolean,
    allAgents:AgentType[],
    selectedAgents:SelectedAgent[],
    copySelectedAgents: SelectedAgent[]
    showMemberModal:boolean,
    searchQuery: string,
    departmentFields: any
    department_title: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DepartmentInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllDepartmentsCallID: string = '';
  getDepartmentInfoApiCallID:string='';
  editDepartmentDetailsCallID:string="";
  getAllAgentsForDeptApiCallId:string="";
  submitMemeberFormApiCallId:string="";
  token:string="";
  getFieldDataApiCallId: string =""
  debounceTimeout: NodeJS.Timeout | null | number = null;
  getMemberBySearchCall: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showModal:false,
      isLoading:false,
      department_title: '',
      copySelectedAgents:[],
      showMemberModal:false,
      departmentFields: [],
      departmentDetails:{
        department_name: "",
        description: "",
        domains_for_department: "",
        department_head: "",
        prime_user: "",
        members:[]
    },
    editFormErrors:{
        department_name: false,
        description: false,
        domains_for_department: false,
        department_head: false,
        prime_user: false,
    },
    editDetailsForm:{
        department_name: "",
        description: "",
        domains_for_department: "",
        department_head: "",
        prime_user: "",
        members:[]
    },
    departmentTouched:false,
    selectedAgents:[],
    allAgents:[],
    searchQuery:'',
      // Customizable Area End
    };
    


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start



    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )


    this.setState({
      isLoading: false
    })

    if (this.inValidApiResponse(responseJson)) {
      this.apiResponseFailureCallBack(apiRequestId, responseJson);
    }


    else if (this.isValidApiResponse(responseJson)) {
      this.apiResponseSucessCallBack(apiRequestId, responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
      this.token =await getStorageData('authToken')
      const { navigation } = this.props
      const deptId = navigation.getParam('id', 0);
    this.getAllAgents()
    this.getDepartmentInfo(deptId)
    this.getFields()
  }

  getAllAgents() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.departmentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRequesterApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllAgentsForDeptApiCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addMember() {
    this.setState({
      selectedAgents: [...this.state.selectedAgents, { id: '', type: '', value: '' }]
    })
  }

  handleMemberModalChange(showModal:boolean){
    this.setState({showMemberModal:showModal})
  }

  onFormSubmit = () => { 
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };

    const memebersArray: AddMemeberArrayPayload[] = [];
    this.state.selectedAgents.forEach((memberData) => {
      memebersArray.push({ "agent_id": memberData?.id, "agent_type": "member" });
    });
    const deptId = this.props.navigation.getParam('id', 0);
    const httpBody = {
      "agent_and_requester_params": memebersArray
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.submitMemeberFormApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDepartmentInfoEndPoint}/${deptId}/${configJSON.addMembersInDepartmentEndPoint}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.agentCreateApiMethod
    );

   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleDeleteSelectedMember = (index:number)=>{
    const selectedMembers = [...this.state.selectedAgents]
    selectedMembers.splice(index,1)
    this.setState({
        selectedAgents:selectedMembers
    })
}

handleSelectedMemberChange = (index:number,agent:SelectedAgent)=>{
    const selectedMembers = [...this.state.selectedAgents]
    selectedMembers.splice(index,1,agent)
    this.setState({
        selectedAgents:selectedMembers
    })
}

isMemberSelected = (memberId: string) => {
  return this.state.selectedAgents?.some(member => member.id === memberId);
};
isOptionDisabled = (value:string):boolean=>{
    const selectedMembers = [...this.state.selectedAgents]
    let isExist=false;
    selectedMembers.forEach((agent:SelectedAgent)=>{
        if(agent.value==value){
            isExist=true
        }
    })
    return isExist
  }

  isDisabled = ():boolean=>this.state.allAgents.length == this.state.selectedAgents.length

  getDepartmentInfo(id:string){
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.departmentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDepartmentInfoEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getDepartmentInfoApiCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchMemberInDepartmentsAPI() {
    this.setState({ isLoading: true });
    const deptId = this.props.navigation.getParam('id', 0);
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };
    
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.departmentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.departmentsApiEndPoint}/${deptId}/search_member_department?search=${this.state.searchQuery}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getMemberBySearchCall = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isValidApiResponse(responseJson: DepartmentResponseJson & AllAgentsResponseJson  ) {
    return responseJson && responseJson.data;
  };

  inValidApiResponse(responseJson: ErrorRes){
    return responseJson && responseJson.errors;
  };

  
  apiResponseFailureCallBack = async (apiRequestCallId: string, responseJson: ErrorRes & string) => {
    if (apiRequestCallId === this.getDepartmentInfoApiCallID) {
      this.parseApiErrorResponse(responseJson);
    }
    if (apiRequestCallId === this.getMemberBySearchCall) {
      this.parseApiErrorResponse(responseJson);
    }
    if (apiRequestCallId === this.getAllAgentsForDeptApiCallId) {
      this.parseApiErrorResponse(responseJson);
    }
    if (apiRequestCallId === this.editDepartmentDetailsCallID) {
        this.setState({showModal:false})
      this.parseApiErrorResponse(responseJson);
    }
    if(apiRequestCallId === this.submitMemeberFormApiCallId){
      this.parseApiErrorResponse(responseJson);
    }
  };
  getFields = () => {
  
    const header = {
      "Content-Type": configJSON.fieldsApiContentType,
      token: this.token
    };
 
    let DeptGetFields = new Message(getName(MessageEnum.RestAPIRequestMessage));
    DeptGetFields.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fieldsApiMethod
    );
    DeptGetFields.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_department/dynamic_department_fields"
    );
    DeptGetFields.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getFieldDataApiCallId = DeptGetFields.messageId;

    runEngine.sendMessage(DeptGetFields.id, DeptGetFields);
  }
  apiResponseSucessCallBack = async (apiRequestCallId: string, responseJson: DepartmentsListData & AllAgentsResponseJson) => {
    if (apiRequestCallId === this.getDepartmentInfoApiCallID) {
      const getSelectedAgents: SelectedAgent[] = [];
      responseJson.data.attributes.members?.length && responseJson.data.attributes.members?.map((memberData)=>{
        getSelectedAgents.push({
          id:memberData.id + "",
          type:memberData.user_type + "",
          value : memberData.full_name + ""
        })
      }) 
      this.setState({department_title: responseJson.data.attributes.department_name,copySelectedAgents:getSelectedAgents, selectedAgents:getSelectedAgents, departmentDetails:responseJson.data.attributes,editDetailsForm:responseJson.data.attributes})
    }
    if (apiRequestCallId === this.getMemberBySearchCall) {
      const memebersArray: MembersList[] = [];
      responseJson?.data?.map((memberData) => {
        memebersArray.push({ ...memberData.attributes, id: memberData?.id, type: memberData?.type });
      })
      this.setState({ departmentDetails: { ...this.state.departmentDetails, members: memebersArray }, editDetailsForm: { ...this.state.departmentDetails, members: memebersArray } });
    }
    if(apiRequestCallId === this.getFieldDataApiCallId){
      if(responseJson.data){

        this.setState({departmentFields : responseJson.data})
      }
    }
    if (apiRequestCallId === this.editDepartmentDetailsCallID) {
        this.setState({departmentDetails:responseJson.data.attributes,showModal:false})
    }
    if (apiRequestCallId === this.getAllAgentsForDeptApiCallId) {
      let newArray: AgentType[] = this.state.selectedAgents.map((agent)=>{
        return {  
          id: agent.id,
          type: agent.type,
          attributes: {
              full_name: agent.value,
              email: "",
              title: "",
              work_phone: null,
              mobile_phone: null,
              secondary_email: "",
              location: "",
              department_id:  "",
              reporting_manager: "",
              address: "",
              time_zone: "",
              time_format: "",
              language: "",
              level: "",
              background_information: "",
              department: {
                  id:  "",
                  name: ""
              }}
            }
      }) 
        this.setState({...this.state,isLoading:false,allAgents:[...responseJson.data,...newArray]})
    }
    if(apiRequestCallId === this.submitMemeberFormApiCallId){
      toast.success(responseJson?.data?.message as string);
      this.setState({showMemberModal:false, selectedAgents:[{ id: '', type: '', value: '' }]})
      const deptId = this.props.navigation.getParam('id', 0);
      this.getDepartmentInfo(deptId);
    }
  };

  handleModalChange(isOpen:boolean){
    if(isOpen){
        this.setState({showModal:isOpen,editDetailsForm:this.state.departmentDetails})
    }else{
        this.setState({showModal:isOpen})
    }
  }

  handleFieldValueChange = (fieldName:FieldName,value:string)=>{
    const formValues = {...this.state.editDetailsForm}
    formValues[fieldName]=value.trimStart()
    this.setState({
        editDetailsForm:formValues
    })
  }

  handleSubmitEditForm = (event:React.FormEvent)=>{
    event.preventDefault()
    this.setState({departmentTouched:true})
    if(!this.getEditFormFieldError("department_name",this.state.editDetailsForm.department_name,true) && !this.getEditFormFieldError("prime_user",this.state.editDetailsForm.prime_user,true) && !this.getEditFormFieldError("department_head",this.state.editDetailsForm.department_head,true)){
      const departmentData = {
        department:this.state.editDetailsForm
      }
      this.editDepartmentDetails(departmentData)
    }
  }

  editDepartmentDetails(values:{
    department:{
      "department_name": string,
      "description": string,
      "domains_for_department": string,
      "department_head": string,
      "prime_user": string
    }
  }){
    const { navigation } = this.props
    const deptId = navigation.getParam('id', 0);

    const headerUpdateDeptartment = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };

    const httpBodyData = values

    const UpdateDepartmentDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editDepartmentDetailsCallID = UpdateDepartmentDataMsg.messageId;
    UpdateDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveDepartmentApiEndPoint}/${deptId}`
    );

    UpdateDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerUpdateDeptartment)
    );

    UpdateDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyData)
    );

    UpdateDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateDepartmentApiMethod
    );

    runEngine.sendMessage(UpdateDepartmentDataMsg.id, UpdateDepartmentDataMsg);
  }

  getEditFormFieldError=(fieldName:FieldName,value:string,departmentTouched:boolean)=>{
    if(fieldName=="department_name" && departmentTouched && value==""){
      return "Department Name is required"
    }
    else if(fieldName=="department_head" && departmentTouched && value){
        if(!(/^[A-Za-zÀ-ú]+ [A-Za-zÀ-ú]+$/.test(value) || /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))){
            return "Invalid Department Head"
        }
        return ""
    }
    else if(fieldName=="prime_user" && departmentTouched && value){
        if(!(/^[A-Za-zÀ-ú]+ [A-Za-zÀ-ú]+$/.test(value) || /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value))){
            return "Invalid Prime User"
        }
        return ""
    }
    else{
        return ""
    }
  }

  getShortUserName = (value:string)=>{
    if(value){
      const userNameArr = value.toUpperCase().split(" ")
      if(userNameArr.length>1){
        return `${userNameArr[0][0]}${userNameArr[1][0]}`
      }else{
        return `${userNameArr[0][0]}`
      }
    }
  }

  handleSearchInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const query = event.target.value.trim();
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout as number);
    }

    this.debounceTimeout = setTimeout(() => {
      this.setState({ searchQuery: query }, () => {
        this.getSearchMemberInDepartmentsAPI();
      });
    }, 500);
  };

  // Customizable Area End
}
