import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { FormikHelpers } from "formik";
import { getStorageData } from "../../../framework/src/Utilities";
import * as Yup from 'yup';
import toast from "react-hot-toast";

export interface DepartmentsListData {
  id: string,
  type: string,
  attributes: {
    department_name: string,
    description: string,
    domains_for_department: string,
    department_head: string,
    prime_user: string
  }
}

export interface EditListData {
  id: string,
  attributes: {
    department_name: string,
    description: string,
    domains_for_department: string,
    department_head: string,
    prime_user: string
  }
}

export interface DepartmentData {
  department_name :  string,
  domains_for_department : string,
  department_description : string,
  department_head : string,
  prime_user : string,
  id : string
}

export interface FormInitalValue {
  department_name: string;
  domains_for_department: string;
  description: string;
  department_head: string;
  prime_user: string;
}

interface DepartmentDeleteResponse {
  data: {
    message: string;
  }
}

interface AllAgentsListData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    email: string;
    title: string;
    work_phone: string;
    mobile_phone: string;
    secondary_email: string;
    location: string;
    department: string;
    reporting_manager: string;
    address: string;
    time_zone: string;
    time_format: string;
    language: string;
    level: string;
    background_information: string;
  }
}

interface AllAgentsResponseJson {
  data: Array<AllAgentsListData>;
}

export interface AgentsOptionList {
  value: string;
  label: string;
}

interface DepartmentResponseJson {
  departments:{
    data: DepartmentsListData[];
  },
  total_count?: number
}

interface SearchDepartmentResponseJson {
  data: DepartmentsListData[];
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
}

interface ErrorRes {
  errors:[
    {
      token: string;
    }
  ]
}

type DepartmentFormValues = {
  department_name:string,
  description:string,
  domains_for_department:string,
  department_head:string,
  primeUserName:string
}
type DepartmentFormErrors = {
  department_name:string,
  description:string,
  domains_for_department:string,
  department_head:string,
  primeUserName:string
}

type FieldName = "department_name" | "description" | "domains_for_department" | "department_head" | "primeUserName"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean,
  txtInputValue: string;
  departmentsList: DepartmentData[];
  departmentsCount: number;
  page: number;
  rowsPerPage: number;
  openListIndex: number;
  showModal: boolean;
  isInfoModalOpen: boolean,
  isDepartmentNameModalOpen: boolean,
  anchorEl: HTMLElement | null | undefined,
  initialValues: FormInitalValue,
  showEditModal:boolean,
  editData: EditListData,
  agentsList: Array<AgentsOptionList>,
  departmentFields : any;
  departmentFormValues:DepartmentFormValues,
  departmentFormErrors:DepartmentFormErrors,
  departmentTouched:boolean,
  searchQuery:string,
  departmentNameValue: string,
  randomState: string
  departmentErrors: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DepartmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDepartmentsBySearchCall: string = '';
  getAllDepartmentsCallID: string = '';
  departmentSaveApiCallId: string = '';
  departmentDeleteApiCallId: string = '';
  departmentUpdateApiCallId: string = '';
  getFieldDataApiCallId : string = "";
  getAllAgentsApiCallId: string = "";
  timeoutId : number | null = null;
  pathname = "/departments";
  token:string = '';
  debounceTimeout: NodeJS.Timeout | null | number = null;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      txtInputValue: "",
      departmentTouched:false,
      departmentsList: [],
      page: 0,
      rowsPerPage: 5,
      openListIndex: -1,
      showModal: false,
      searchQuery:'',
      isInfoModalOpen: false,
      anchorEl: null,
      randomState: '',
      departmentFormValues:{
        department_name:'',
        description:'',
        domains_for_department:'',
        department_head:'',
        primeUserName:''
      },
      departmentFormErrors:{
        department_name:'Department Name is required*',
        description:'',
        domains_for_department:'',
        department_head:'',
        primeUserName:''
      },
      initialValues: {
        department_name: '',
        domains_for_department: '',
        description: '',
        department_head: '',
        prime_user: '',
      },
      showEditModal: false,
      editData:{
        id: "",
        attributes: {
          department_name: "",
          description: "",
          domains_for_department: "",
          department_head: "",
          prime_user: ""
        }
      },
      agentsList: [],
      departmentFields : [],
      isDepartmentNameModalOpen: false,
      departmentNameValue : "",
      departmentsCount: 0,
      departmentErrors: {
        department_name: '',
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    this.setState({
      isLoading: false
    })

    if (this.inValidResponse(responseJson)) {
      this.responseFailureCallBack(apiRequestId, responseJson);
    }
    else if (this.isValidResponse(responseJson)) {
      this.responseSucessCallBack(apiRequestId, responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.token =await getStorageData('authToken')
    this.getAllDepartments();
    this.getAllAgents();
    this.getFields()
  }

  handleSearchInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const query = event.target.value.trim();
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout as number);
    }

    this.debounceTimeout = setTimeout(() => {
      this.setState({ searchQuery: query }, () => {
        this.getSearchDepartmentsAPI();
      });
    }, 500);
  };


  isValidResponse = (responseJson: DepartmentResponseJson & AllAgentsResponseJson & DepartmentDeleteResponse ) => {
    return responseJson && (responseJson.data || responseJson?.departments);
  };

  inValidResponse = (responseJson: ErrorRes) => {
    return responseJson && responseJson.errors;
  };

  responseFailureCallBack = async (apiRequestCallId: string, responseJson: ErrorRes & string) => {
    if (apiRequestCallId === this.getAllDepartmentsCallID) {
      this.parseApiErrorResponse(responseJson);
    }
    if (apiRequestCallId === this.departmentSaveApiCallId) {
      const errorMessage = responseJson?.errors[0];
      this.setState({
        departmentErrors: {
          ...this.state.departmentErrors,
          department_name: errorMessage,
        },
      });
    }
    if (apiRequestCallId === this.departmentDeleteApiCallId) {
      this.parseApiErrorResponse(responseJson);
    }
    if (apiRequestCallId === this.departmentUpdateApiCallId) {
      this.setState({ showEditModal: false });
      this.parseApiErrorResponse(responseJson);
    }
    if (apiRequestCallId === this.getAllAgentsApiCallId) {
      this.parseApiErrorResponse(responseJson);
    }
    if(apiRequestCallId === this.getDepartmentsBySearchCall){
      this.parseApiErrorResponse(responseJson);
    }
  };

  responseSucessCallBack = async (apiRequestCallId: string, responseJson: SearchDepartmentResponseJson & DepartmentResponseJson & AllAgentsResponseJson & DepartmentDeleteResponse) => {
    if (apiRequestCallId === this.getAllDepartmentsCallID) {
      this.handleDepartmentsApiDataResponse(responseJson);
    }
    if(apiRequestCallId === this.getDepartmentsBySearchCall){
      this.handleSearchDepartmentsApiDataResponse(responseJson);
    }
    if (apiRequestCallId === this.departmentSaveApiCallId) {
      toast.success(configJSON.departmentAddMessage);
      this.getAllDepartments()
      this.setState({
        showModal: false,
        departmentFormValues: {
          department_name: '',
          domains_for_department: '',
          description: '',
          department_head: '',
          primeUserName: '',
        },
        departmentTouched:false
      })
    }
    if (apiRequestCallId === this.departmentDeleteApiCallId) {
      toast.success(configJSON.departmentRemoveMessage);
      this.getAllDepartments();
    }
    if (apiRequestCallId === this.departmentUpdateApiCallId) {
      toast.success(configJSON.departmentUpdateMessage);
      this.getAllDepartments();
      this.setState({
        editData: {
          id: "",
          attributes: {
            department_name: "",
            description: "",
            domains_for_department: "",
            department_head: "",
            prime_user: ""
          }
        },
        showEditModal: false
      });
    }
    if (apiRequestCallId === this.getAllAgentsApiCallId) {
      this.handleAllAgentsApiDataResponse(responseJson);
    }
    if(apiRequestCallId === this.getFieldDataApiCallId){
      if(responseJson.data){
        this.setState({departmentFields : responseJson.data})
      }
    }
  };

  getAllDepartments() {
    this.setState({ isLoading: true });
    const pageNumber: number = this.state.page + 1;
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.departmentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.departmentsApiEndPoint}?page=${pageNumber}&per_page=${this.state.rowsPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getAllDepartmentsCallID = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSearchDepartmentsAPI() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };
    
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.departmentsApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.serachDepartmentApiEndPoint}?search=${this.state.searchQuery}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getDepartmentsBySearchCall = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getFields = () => {
  
    const header = {
      "Content-Type": configJSON.fieldsApiContentType,
      token: this.token
    };
 
    let DeptGetFields = new Message(getName(MessageEnum.RestAPIRequestMessage));
    DeptGetFields.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.fieldsApiMethod
    );
    DeptGetFields.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_department/dynamic_department_fields"
    );
    DeptGetFields.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getFieldDataApiCallId = DeptGetFields.messageId;

    runEngine.sendMessage(DeptGetFields.id, DeptGetFields);
  }

  getShortUserName = (value:string)=>{
    if(value){
      const userNameArr = value.toUpperCase().split(" ")
      if(userNameArr.length>1){
        return `${userNameArr[0][0]}${userNameArr[1][0]}`
      }else{
        return `${userNameArr[0][0]}`
      }
    }
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  onPageChange = (_: unknown, page: number) => {
    this.setState({ page },()=>{
      this.getAllDepartments();
    });
  };

  onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) },()=>{
      this.getAllDepartments();
    });
  };

  createDepartment = (values:{
    department:{
      "department_name": string,
      "description": string,
      "domains_for_department": string,
      "department_head": string,
      "prime_user": string
    }
  })=>{
    const headerSaveDeptartment = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };

    const httpBodyValues = values

    const SaveDepartmentDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.departmentSaveApiCallId = SaveDepartmentDataMsg.messageId;
    SaveDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDepartmentApiEndPoint
    );

    SaveDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerSaveDeptartment)
    );

    SaveDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyValues)
    );

    SaveDepartmentDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveDepartmentApiMethod
    );

    runEngine.sendMessage(SaveDepartmentDataMsg.id, SaveDepartmentDataMsg);
  }
  submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    this.setState({ departmentTouched: true }, () => {
      const { departmentFormValues } = this.state;
      const departmentErrors = {
        department_name: this.getFieldError(
          "department_name",
          departmentFormValues.department_name,
          true
        ),
        department_head: this.getFieldError(
          "department_head",
          departmentFormValues.department_head,
          true
        ),
        primeUserName: this.getFieldError(
          "primeUserName",
          departmentFormValues.primeUserName,
          true
        ),
        description: this.getFieldError(
          "description",
          departmentFormValues.description,
          true
        ),
        domains_for_department: this.getFieldError(
          "domains_for_department",
          departmentFormValues.domains_for_department,
          true
        ),
      };
      const hasErrors = Object.values(departmentErrors).some(
        (error) => error !== ""
      );
      if (hasErrors) {
        this.setState({ departmentErrors });
        return;
      }
      const departmentData = {
        department: {
          department_name: departmentFormValues.department_name.trim(),
          description: departmentFormValues.description,
          domains_for_department: departmentFormValues.domains_for_department,
          department_head: departmentFormValues.department_head,
          prime_user: departmentFormValues.primeUserName,
        },
      };
      this.createDepartment(departmentData);
    });
  };

  handlesubmit = (values: FormInitalValue, formikHelpers: FormikHelpers<FormInitalValue>) => {
    const headerSaveDept = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };

    const httpBody = {
      department: values
    };

    const SaveDepartmentData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.departmentSaveApiCallId = SaveDepartmentData.messageId;
    SaveDepartmentData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDepartmentApiEndPoint
    );

    SaveDepartmentData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerSaveDept)
    );

    SaveDepartmentData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    SaveDepartmentData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveDepartmentApiMethod
    );

    runEngine.sendMessage(SaveDepartmentData.id, SaveDepartmentData);
  }
  openAccordian = (index: number) => {
    this.setState((prevState) => ({
      openListIndex: prevState.openListIndex === index ? -1 : index,
    }));
  };

  handleDepartmentClick(id:string){
    this.props.navigation.navigate('DepartmentInfo', { id: id });
  }
  getFieldError = (fieldName: FieldName, value: string, departmentTouched: boolean): string => {
    const { departmentFields } = this.state;
    const field = departmentFields.find((field: any) => field.attributes.name === fieldName);
    if (!field) return "";
    const { optional } = field.attributes;

    const errorMessages: { [key in FieldName]?: string } = {
        department_name: "Department Name is required",
        description: "Description is required",
        domains_for_department: "Domains for department is required",
        department_head: "Department head is required",
    };

    const isValidValue = (val: string): boolean =>
        /^[A-Za-zÀ-ú]+ [A-Za-zÀ-ú]+$/.test(val) || /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val);

    if (departmentTouched) {
        if (optional && value === "") {
            return errorMessages[fieldName] || "";
        }
        if (fieldName === "department_head" && value && !isValidValue(value)) {
            return "Invalid Department Head";
        }
    }

    return "";
};
handleFieldChange = (fieldName: FieldName, value: string) => {
  const formValues = { ...this.state.departmentFormValues };
  formValues[fieldName] = value.trimStart();

  const updatedErrors = { ...this.state.departmentErrors };
  updatedErrors[fieldName] = this.getFieldError(fieldName, value, true);
  if (fieldName === "department_name") {
      updatedErrors["description"] = formValues["description"] === "" ? "Description is required" : "";
      updatedErrors["domains_for_department"] = formValues["domains_for_department"] === "" ? "Domains for department is required" : "";
      updatedErrors["department_head"] = formValues["department_head"] === "" ? "Department head is required" : "";
  }

  this.setState({
      departmentFormValues: formValues,
      departmentErrors: updatedErrors,
  });
};
  getValidationSchema() {
    const { departmentFields } = this.state;

    let validationRules: { [key: string]: any } = {};

    departmentFields.forEach((field: any) => {
        const { name, optional } = field.attributes;
        if (optional === false) {
            // If the field is optional, no validation is required
            return;
        }
        // If the field is required, add validation
        validationRules[name] = Yup.string().required('This field is required');
        // You can add more validation rules here as needed
    });

    return Yup.object().shape(validationRules);
}

  notOnlyWhitespace = (errorMessage: string) =>
  Yup.string().test(
    'not-only-whitespace',
    errorMessage,
    value => !value || value.trim().length > 0,
  );

  stringWithSpecialCharValidation = (fieldName:string, configPattern:RegExp) =>
  Yup.string()
    .required(`${fieldName} is required`)
    .matches(configPattern, `${fieldName} cannot start with a special character`)
    .concat(this.notOnlyWhitespace(`${fieldName} cannot be empty or only whitespace`));



  handleModalOpen(value: boolean) {
    this.setState({
      showModal: value,
      departmentFormValues : {
        department_name:'',
        department_head:'',
        description:"",
        domains_for_department:"",
        primeUserName:""
      },
      departmentTouched:false
    })
  }

  handleModalClose = ()=>{
    this.setState({
      ...this.state,
      showModal:false,
      departmentFormErrors:{
        department_name:'',
        department_head:'',
        description:"",
        domains_for_department:"",
        primeUserName:""
      },
      departmentTouched:false
    })
  }

  handleSubmit = (e: any) => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };

    const httpBody = {
      department: e
    };

    const SaveDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.departmentSaveApiCallId = SaveDetails.messageId;
    SaveDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveDepartmentApiEndPoint
    );

    SaveDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    SaveDetails.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    SaveDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveDepartmentApiMethod
    );

    runEngine.sendMessage(SaveDetails.id, SaveDetails);
  }

  handleNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(message)
  }

  handleDepartmentDelete = (id:string) => {
    const header={
      "Content-Type":configJSON.departmentsApiContentType,
      token:this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.departmentDeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteDepartmentApiEndPoint+`/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteDepartmentApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);   
  }

  handleDepartmentEdit = (value:boolean,department:DepartmentData) => {
    const { id,department_description, ...restOfFields } = department;
    this.setState({
      showEditModal:value,
      editData:{attributes: { description:department_description, ...restOfFields },
      id: id}
    })
  }

  handleEditModalClose = () => {
    this.setState({
      showEditModal:false
    })
  }

  handleEditSubmit = (value: any) => { 
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.departmentsApiContentType,
      token: this.token
    };
    const body = {
      department: value
    };   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.departmentUpdateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDepartmentApiEndPoint+`/${this.state.editData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateDepartmentApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleDepartmentsApiDataResponse = (responseJson: DepartmentResponseJson) => {
    this.setState({
      departmentsCount : responseJson?.total_count ?? 0,
      departmentsList: responseJson?.departments?.data.map((item: DepartmentsListData) => ({
        department_name: item.attributes.department_name,
        domains_for_department: item.attributes.domains_for_department,
        department_description: item.attributes.description,
        department_head: item.attributes.department_head,
        prime_user: item.attributes.prime_user,
        id: item.id
      }))
    });
  }

  handleSearchDepartmentsApiDataResponse = (responseJson: SearchDepartmentResponseJson) => {
    this.setState({
      departmentsCount : responseJson?.data?.length ?? 0,
      departmentsList: responseJson?.data.map((item: DepartmentsListData) => ({
        department_name: item.attributes.department_name,
        domains_for_department: item.attributes.domains_for_department,
        department_description: item.attributes.description,
        department_head: item.attributes.department_head,
        prime_user: item.attributes.prime_user,
        id: item.id
      }))
    });
  }

  apiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.token
    };
    let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAllAgents = async () => {
    this.setState({ isLoading: true });
    this.getAllAgentsApiCallId = await this.apiCall({
      contentType: configJSON.getAgentsListApiContentType,
      method: configJSON.getAgentsListApiMethod,
      endPoint: configJSON.getAgentsListApiEndPoint
    })
  };

  handleAllAgentsApiDataResponse = (responseJson: AllAgentsResponseJson) => {
    this.setState({
      agentsList: responseJson.data.map((item: AllAgentsListData) => ({
        label: item.attributes.full_name,
        value: item.attributes.full_name
        }
      ))
    });
  };

  handelInfoModal(value: boolean){
    this.setState({
      isInfoModalOpen: value
    })
  }

  handelDepartmentNameModal(event: React.MouseEvent<HTMLElement> | null, value: boolean, departmentName: string){
    this.setState({
      isDepartmentNameModalOpen: value,
      departmentNameValue : departmentName,
      anchorEl: event?.currentTarget,
    })
  }
  
  handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
        isInfoModalOpen: true,
        anchorEl: event?.currentTarget,
    });
};

  // Customizable Area End
}
