
import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
// Customizable Area End

export const RoleHeader = ({ heading, renderInfoIcon = null }: { heading: string, renderInfoIcon?: any}) => (
  // Customizable Area Start
  <>
    <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
         <Box
                     style={webStyles.topheader}>
                      <span 
                      style={webStyles.navigation}>
                        <span > Settings
        
                        </span>
                        <span>
                          {" > "}</span>
                        <span>
                        User management</span>
                        <span>
                          {" > "}</span>
                        <span >
                          Roles</span>
                      </span>
                    </Box>
        
        <Box style={styles.moreBox}>
          <span style={{ width: '36px', height: '36px', }}>
            <MoreVert style={styles.moreIcon} />
          </span>
        </Box>
    </Box>
     <Box
     style={{
       marginTop: "60px",
       marginBottom: "50px",
     }}
   >
     <Box
       style={{
         display: "flex",
         alignItems: "center",
         gap: "5px",
       }}
     >
       <h1 style={styles.heading} data-test-id="roleTestID">{heading}</h1>
       {renderInfoIcon ? renderInfoIcon : 
       <EditIcon fontSize="small" data-test-id="edit" style={{color:'#33354D',width:'18px',height:'18px'}} onClick={() =>{}} />}
     </Box>
    </Box>
  </>
    // Customizable Area End
  )

  // Customizable Area Start
  const styles: Record<string, React.CSSProperties> = {
    heading: {
      color: "#33354D",
      fontSize: "30px",
      margin: "10px 0 0",
      lineHeight: "40px",
      fontWeight: 600,
    },

    navigation: {
      fontFamily: "Manrope",
      fontSize: "14px",
      lineHeight: "19px",
  
    },
    moreBox: {
      background: '#e6deed', 
      borderRadius: '5px'
    },

    moreIcon: { 
      width: '24px', 
      height: '24px', 
      color: 'black', 
      padding: '5px' 
    }
  }
  const webStyles = {
    topheader: {
      display: "flex",
      justifyContent: "space-between",
      padding: '24px 0'
    } as const,
    navigation: {
      color: '#3D405C',
      display:'flex',
      alignItems:'center',
      gap:'8px',
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: 'normal',
    } as const,
  }
  // Customizable Area End

    
  