import React from 'react';

//Customizable Area Start
import Paper from '@material-ui/core/Paper';
import { Button, TextField, Box, ThemeProvider, Container, createTheme, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography, Link, withStyles, MobileStepper } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';


import { Path1 } from "./assets"
import { Path2 } from "./assets"
import { Path24 } from "./assets"
import { Path25 } from "./assets"
import { Path26 } from "./assets"
import { FinalLogo1 } from "./assets";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Toaster } from 'react-hot-toast';

const stepValidationSchemas = [
  yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
  }),
  yup.object().shape({
    verificationCode: yup.string().length(4, 'OTP must be exactly 4 digits')
      .matches(/^\d+$/, 'OTP must contain only digits'),
  }),
  yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  }),
];

const buttonContainerStyle = {
  display: "flex",
  // width: 452,
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 12,
  marginBottom: "20px",
  borderRadius: "0px 0px var(--1, 8px) var(--1, 8px)",
  background: "var(--basic-white, #FFF)",
  // padding: "10px 0px",

};

const webStyle = {
  finalLogoWrapper: {
    position: 'absolute',
    left: '120px',
    right: '1193px',
    bottom: '920px',
    top: '52px',

  },
  forgotPasswordWrapperEmail: {
    height: 440,
    flexShrink: 0,
    position: "relative",
    top: "252px",
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    bottom: "400px",
    padding: "20px"
  },
  forgotPasswordWrapperOTP: {
    height: 440,
    flexShrink: 0,
    position: "relative",
    top: "252px",
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    bottom: "400px",
    padding: "20px"
  },
  forgotPasswordWrapperPassword: {
    height: 440,
    flexShrink: 0,
    position: "relative",
    top: "252px",
    display:'flex',
    justifyContent:'center',
    alignItems:"center",
    bottom: "400px",
    padding: "20px"
  },
  forgotPasswordEmail: {
    width: 500,
    // padding: 20,
    borderRadius: "var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    boxShadow:
      "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

  },
  forgotPasswordWrapperEmailStepper: {
    left: "14px",
    right: "182px",
    bottom: "-26px",

    position: "absolute"
  }
  ,
  forgotPasswordOTP: {
    width: 500,
    // padding: 20,
    borderRadius: "var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    boxShadow:
      "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

  },
  forgotPasswordPassword: {
    width: 500,
    // padding: 20,
    borderRadius: "var(--1, 8px)",
    background: "var(--basic-white, #FFF)",
    boxShadow:
      "0px 8px 32px 0px rgba(135, 96, 169, 0.16), 0px 4px 8px 0px rgba(135, 96, 169, 0.13)",

  },
  footerMainWrapperForgot: {
    display: "flex", justifyContent: "space-between"
  },
  path2StyleForgot: {
    position: "absolute", zIndex: 1, bottom: 0
  },
  path1StyleForgot: {
    position: "absolute", zIndex: 1, bottom: 0, left: 0
  },
  path24StyleForgot: {
    position: "absolute", zIndex: 1, bottom: 0, right: 0
  },
  path25StyleForgot: {
    position: "absolute", zIndex: 1, bottom: 0, right: "45px"
  }
}

const steps = ['Enter Email', 'Verification Code', 'Set Password'];
import Loader from '../../../components/src/Loader.web';
//Customizable Area End

import ForgotPasswordController, { Props } from './ForgotPasswordController.web';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8760A9",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { activeStep } = this.state;
    // Customizable Area Start
    //Customizable Area End

//istanbul ignore next/
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Toaster/>
          <Box style={{position:'fixed',height:'100%',width:'100%',pointerEvents:'none',overflow:'hidden',zIndex:1000}}>
                <Loader loading={this.state.isLoading} />
                </Box>
        <Container style={{ maxWidth: 1440, height: 1024 }}>
          <Box
            sx={webStyle.finalLogoWrapper}
          >
            <img src={FinalLogo1} alt="My Image" />
          </Box>
          <Typography>{this.state.isOTPReceived && `OTP: ${this.state.pin}`}</Typography>
          <Box sx={webStyle.forgotPasswordWrapperEmailStepper}>
            <MobileStepper
              variant="progress"
              steps={steps.length}
              // position="static"
              activeStep={activeStep}
              backButton={undefined}
              nextButton={undefined}
            />
          </Box>
          {activeStep === 0 && (
            <Box sx={webStyle.forgotPasswordWrapperEmail}>
              <Paper elevation={3}
                style={webStyle.forgotPasswordEmail}
              >

                <Box style={{ padding: "24px" }}>
                  <Typography variant="h4" style={{ marginBottom: "18.5px" }} component="h2">
                    Password Reset
                  </Typography>
                  <Typography style={{ marginBottom: "18.5px" }} variant="subtitle1" component="div">
                    Provide work email associated with your account below {/*UI Engine::From Sketch*/}
                  </Typography>
                  <Formik
                    initialValues={{
                      email: '',
                    }}
                    validationSchema={stepValidationSchemas[0]}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      this.setState({ email: values.email })
                      this.fogotPasswordOTP();
                      // this.handleNextStep(this.fogotPasswordOTP())
                    }}
                  >
                    {(formikProps) => (
                      <Form translate={undefined}>
                        {/* Step 1 content */}
                        <Box style={{ marginTop: "60px", marginBottom: "60px" }}>
                          <InputLabel style={{ marginBottom: "5px" }} htmlFor="email">
                            Work Email<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            as={TextField}
                            fullWidth
                            variant="outlined"
                            error={Boolean(formikProps.touched.email && formikProps.errors.email)}
                            helperText={formikProps.touched.email && formikProps.errors.email}
                          />
                        </Box>
                        <Box sx={buttonContainerStyle}>
                          <Button data-testid='cancelStep0' color="primary" style={{fontSize:16,textTransform:'none'}} onClick={()=> this.props.navigation.navigate('EmailAccountLoginBlock')}>Cancel</Button>

                          <Button
                            data-test-id={"btnEmailLogIn"}
                            type="submit"
                            disabled={this.state.isLoading}
                            style={{ backgroundColor: '#8760A9' }}
                          >
                            <span style={{
                              color: 'white',
                              textTransform: 'none',
                            }}>Submit</span>
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Paper>
            </Box>
          )}
          {activeStep === 1 && (
            <Box sx={webStyle.forgotPasswordWrapperOTP}>

              <Paper elevation={3}
                style={webStyle.forgotPasswordOTP}
              >
                <Box style={{ padding: "24px" }}>
                  <Typography variant="h4" style={{ marginBottom: "18.5px" }} component="h2">
                    Password Reset
                  </Typography>
                  <Typography style={{ marginBottom: "18.5px" }} variant="subtitle1" component="div">
                    Please type in verification code that we have sent to email {/*UI Engine::From Sketch*/}
                  </Typography>
                  <Formik
                    initialValues={{
                      verificationCode: '',
                    }}
                    validationSchema={stepValidationSchemas[1]}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      this.setState({ forgotPasswordOTP: values.verificationCode })
                      this.verifyFogotPasswordOTP()
                      this.handleNextStep(this.state.otpVerifiedSuccessfully)
                    }}
                  >
                    {(formikProps) => (
                      <Form translate={undefined}>
                        <Box>
                          <InputLabel htmlFor="verificationCode" style={{ marginBottom: "5px", marginTop: "60px" }}>
                            Verification Code<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Field
                            type="text"
                            name="verificationCode"
                            id="verificationCode"
                            placeHolder="Enter verification code"
                            as={TextField}
                            fullWidth
                            variant="outlined"
                            error={Boolean(formikProps.touched.verificationCode && formikProps.errors.verificationCode)}
                            helperText={formikProps.touched.verificationCode && formikProps.errors.verificationCode}
                          />
                          <Typography style={{ color: "red" }}>{this.state.pinError}</Typography>

                        </Box>

                        <Typography style={{ marginBottom: "50px", marginTop: "10px" }}>
                          Didn't received the code? <Link style={{cursor:'pointer'}} onClick={this.resendFogotPasswordOTP}>Resend it</Link>
                        </Typography>
                        <Box sx={buttonContainerStyle}>
                          <Button data-testid='cancel_step1' color="primary" 
                            disabled={this.state.isLoading} style={{textTransform:'none',fontSize:16}} onClick={()=> {
                            this.setState({activeStep:0})
                          }}>Cancel</Button>

                          <Button
                            data-test-id={"btnEmailLogIn"}
                            type="submit"
                            disabled=
                            {this.state.isLoading

                            }
                            style={{ backgroundColor: '#8760A9' }}
                          >
                            <span style={{
                              color: 'white',
                              textTransform: 'none',
                            }}>Submit</span>
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Paper>
            </Box>
          )}
          {activeStep === 2 && (
            <Box sx={webStyle.forgotPasswordWrapperPassword}>

              <Paper elevation={3}
                style={webStyle.forgotPasswordPassword}
              >
                <Box style={{ padding: "24px" }}>
                  <Typography variant="h4" style={{ marginBottom: "18.5px" }} component="h2">
                    Password Reset
                  </Typography>
                  <Typography style={{ marginBottom: "18.5px" }} variant="subtitle1" component="div">
                    Provide work email associated with your account below {/*UI Engine::From Sketch*/}
                  </Typography>
                  <Formik
                    initialValues={{
                      password: '',
                      confirmPassword: '',
                    }}
                    validationSchema={stepValidationSchemas[2]}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      this.setState({ password: values.confirmPassword })
                      this.resetPassword()
                    }}
                  >
                    {(formikProps) => (
                      <Form translate={undefined}>
                        <Box style={{marginBottom:'16'}}>
                          <InputLabel style={{ marginBottom: "5px" }} htmlFor="password">
                            Password<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Field
                            type={!this.state.viewPassword ? 'text' : 'password'}

                            name="password"
                            id="password"
                            as={OutlinedInput}
                            fullWidth
                            error={Boolean(formikProps.touched.password && formikProps.errors.password)}
                            helperText={formikProps.touched.password && formikProps.errors.password}

                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                onClick={this.ViewNewPassword}
                                >
                                  {this.state.viewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <span style={{ color: "red" }}>{formikProps.touched.password && formikProps.errors.password}</span>

                        </Box>
                        <Box style={{marginBottom:'16'}}>
                          <InputLabel style={{ marginBottom: "5px" }} htmlFor="confirmPassword">
                            Retype Password<span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <Field
                            type={!this.state.enablePasswordField ? 'text' : 'password'}

                            name="confirmPassword"
                            id="confirmPassword"
                            as={OutlinedInput}
                            fullWidth
                            error={Boolean(formikProps.touched.confirmPassword && formikProps.errors.confirmPassword)}
                            helperText={formikProps.touched.confirmPassword && formikProps.errors.confirmPassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                onClick={this.viewRetype}
                                >
                                  {
                                  
                                      this.state.enablePasswordField 
                                      ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <span style={{ color: "red" }}>{formikProps.touched.confirmPassword && formikProps.errors.confirmPassword}</span>

                        </Box>
                        <Box sx={buttonContainerStyle}>
                          <Button data-testid='cancel_step2' disabled=
                          {
                            this.state.isLoading
                            
                          }style={{textTransform:'none',fontSize:16}} color="primary" onClick={()=>{
                            this.setState({activeStep:0})
                          }}>Cancel</Button>

                          <Button disabled={
                            this.state.isLoading}
                            data-test-id={"btnEmailLogIn"}
                            type="submit"
                            style={{ backgroundColor: '#8760A9' }}
                          >
                            <span style={{
                              color: 'white',
                              textTransform: 'none',
                            }}>Submit</span>
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Paper>
            </Box>
          )}


          <Box sx={webStyle.footerMainWrapperForgot}  >
            <Box style={{ display: "flex", }} >
              <Box sx={webStyle.path2StyleForgot}  >
                <img src={Path2} alt="Path2" />
              </Box>
              <Box sx={webStyle.path1StyleForgot} >
                <img src={Path1} alt="Path1" />
              </Box>
            </Box>
            <Box style={{ display: "flex", }}>
              <Box sx={webStyle.path24StyleForgot} >
                <img src={Path24} alt="Path24" />
              </Box >
              <Box sx={webStyle.path25StyleForgot} >
                <img src={Path25} alt="Path23" />
              </Box>
              <Box sx={webStyle.path24StyleForgot}>
                <img src={Path26} alt="Path26" />
              </Box>
            </Box>
          </Box>
        </Container >
      </ThemeProvider >
    );
    //Customizable Area End
  }
}
