import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import RichTextEditor, { EditorValue } from 'react-rte';
import toast from "react-hot-toast";

interface AgentDetail {
  id: number;
  full_name: string;
}

export interface RolesListData {
  id: string,
  type: string,
  attributes: {
    name: string;
    description: string;
    role_type?: string;
    permissions: [{ fields: any}];
    agent_count?: number
    agent_details: Array<AgentDetail>;
  }
}

interface FormInitalValue {
  name: string;
  description: string;
  role_type: string;
  permissions: PermissionList;
}

export interface PermissionList {
  permissionOne: boolean;
  permissionTwo: boolean;
  permissionThree: boolean;
  permissionFour: boolean;
  permissionFive: boolean;
  permissionSix: boolean;
  permissionSeven: boolean;
  permissionEight: boolean;
  permissionNine: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string,
  isLoading: boolean,
  initialValues: FormInitalValue;
  showModal: boolean;
  isInfoModalOpen: boolean;
  adminPermissions: PermissionList;
  openListIndex: number;
  token: string;
  editorValue: string;
  errors: { name: string | null };
  currentRole: RolesListData | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateEditAdminRoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAdminRoleApiCallId:string = "";
  getRoleApiCallId:string="";
  updateAdminRoleApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "",
      token: localStorage.getItem("authToken") || "",
      isLoading: false,
      
      initialValues: {
        name: "",
        role_type: "admin_roles",
        description: "",
        permissions: this.permissionsList
      },
      showModal: false,
      isInfoModalOpen: false,
      adminPermissions: this.permissionsList,
      openListIndex: -1,
      editorValue:'',
      errors: { name: null },
      currentRole: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    switch (apiRequestId) {
      case this.createAdminRoleApiCallId:
        this.handleCreateUpdateAdminRoleResponse(responseJson, true);
        break;
      case this.getRoleApiCallId:
        this.handleGetRoleResponse(responseJson);
        break;
      case this.updateAdminRoleApiCallId:
        this.handleCreateUpdateAdminRoleResponse(responseJson, false);
        break;
      default:
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.getSpecificAdminRole(id);
    }
  }

  permissionsList = { permissionOne: false , 
    permissionTwo: false, permissionThree: false, 
    permissionFour: false,
    permissionFive: false, permissionSix: false, 
    permissionSeven: false,
    permissionEight: false,  permissionNine: false};

  handleCreateUpdateAdminRoleResponse(responseJson: {data?: RolesListData, errors?: string[]}, isCreated: boolean) {
    if (responseJson.data) {
      toast.success(`Admin role ${isCreated ? 'Added' : 'updated'} successfully.`);
      this.props.navigation.navigate("Rolesandpermissions");
    } 
    if (responseJson.errors) {
      if (typeof responseJson.errors[0] === "object") {
        const error:any = Object.values(responseJson.errors[0])[0] || "";
        toast.error(error);
      } 
      else {
        this.setState({ errors: {...this.state.errors, name: responseJson.errors[0] }});
      }
    }
  }

  handleGetRoleResponse(responseJson: { data: RolesListData }) {
    if (responseJson.data) {
      const currentRole = responseJson.data;  
      const permissions = currentRole.attributes.permissions?.[0]?.fields || {};
    
      this.setState({
        currentRole: responseJson.data,
        editorValue: currentRole.attributes.description,
        initialValues: {
          ...this.state.initialValues,
          name: currentRole.attributes.name,
          description: currentRole.attributes.description,
          permissions: {
            permissionOne: permissions.manage_team_dashboards || false,
            permissionTwo: permissions.manage_agents || false,
            permissionThree: permissions.manage_requester || false,
            permissionFour: permissions.manage_ticket_templates || false,
            permissionFive: permissions.manage_change_templates || false,
            permissionSix: permissions.manage_departments || false,
            permissionSeven: permissions.create_and_edit_service_request_items || false,
            permissionEight: permissions.manage_sla_and_ola_policies || false,
            permissionNine: permissions.play_god_mode || false,
          },
        },
      });
    }
    
  }

  handleDescription = (value: string, setFieldValue: (field: string, value: any) => void) => {
    const htmlContent = value
    console.log('dddddd',htmlContent)
    const isEmptyContent = htmlContent === '<p><br></p>' || !htmlContent.trim();
    this.setState({ editorValue: value });
    setFieldValue('description', isEmptyContent ? '' : htmlContent);
  }

  clearApiError = () => {
    this.setState({ errors: { ...this.state.errors, name: null }});
  }

  handlePermissions = (permission: string, setFieldValue: (field: string, value: any) => void) => {
    const checked = !this.state.adminPermissions[permission as keyof PermissionList];
    setFieldValue(`permissions.${permission}`, checked);
    this.setState((prevState) => 
      ({adminPermissions: 
        {...prevState.adminPermissions, [permission]: checked }
      }));
  }

  handleSubmit = (values: FormInitalValue): void => {
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.editAdminRole(values);
    } else {
      this.createAdminRole(values);
    }
  }

  roleBody = (values: FormInitalValue) => {
    const httpBody = {
      role: {
        name: values.name,
        description: values.description,
        role_type: values.role_type,
        permissions_attributes: [
        {
          option_name: "admin can",
          fields: {
            perform_admin_operational_admin_actions: true,
            manage_team_dashboards: values.permissions.permissionOne,
            manage_agents: values.permissions.permissionTwo,
            manage_requester: values.permissions.permissionThree,
            manage_ticket_templates: values.permissions.permissionFour,
            manage_change_templates: values.permissions.permissionFive,
            manage_departments: values.permissions.permissionSix,
            create_and_edit_service_request_items: values.permissions.permissionSeven,
            manage_sla_and_ola_policies: values.permissions.permissionEight,
            play_god_mode: values.permissions.permissionNine
          }
        } 
      ]
      }
    }
    return httpBody;
  }
  createAdminRole = (values: FormInitalValue) => {
    const header = {
      'Content-Type': configJSON.fieldsApiContentType,
      token: this.state.token
    };
    const httpBody = this.roleBody(values);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createAdminRoleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.rolesApiEndpoint}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createRoleApiMethod
    );

   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editAdminRole = (values: FormInitalValue) => {
    const id = this.props.navigation.getParam("id");
    const header = {
      'Content-Type': configJSON.fieldsApiContentType,
      token: this.state.token
    };
    const httpBody = this.roleBody(values);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateAdminRoleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.rolesApiEndpoint}/${id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRolesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSpecificAdminRole = (id: string | number) => {
    const header = {
      'Content-Type': configJSON.fieldsApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRoleApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.rolesApiEndpoint}/${id}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRolesApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigation = (path:string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

  // Customizable Area End
}
