import React from "react";
// Customizable Area Start
import { Field, Form, Formik } from "formik";
import { styled } from "@material-ui/core/styles";
import { Box, Button, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@material-ui/core";
import * as Yup from 'yup';
import { CustomRadio } from "./CreateEditAgentRole.web";
import RichTextEditor from 'react-rte';
import { PermissionList } from "./CreateEditAdminRoleController";
import { ContentBlock } from 'draft-js';
export const configJSON = require("./config");
import  "../assets/form.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
// Customizable Area End

// Customizable Area Start
export const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    [{ 'color': [] }, { 'background': [] }],
    ['image'],
  ],
};
export const formats = [
  'list', 'bullet',
  'bold', 'italic', 'underline', 'strike',
  'align',
  'color', 'background',
  'image'
];
const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required('Name field is required')
      .min(4, 'Please enter at least 4 characters')
      .max(50, 'Maximum limit for name is 50')
      .matches(/^[A-Za-z][A-Za-z0-9\s]*$/, 'Name cannot start with a number or special character'),
    description: Yup.string()
    .required('Description is required')
    .test('is-not-empty', 'Description is required', (value) => {
      return value && value.trim() !== '';
    })
  });
  
  const PermissionListItemText = styled(ListItemText)({
    "&.MuiListItemText-root .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: "Manrope",
      color: "#33354D",
    }
  });
// Customizable Area End

// Customizable Area Start
const RoleForm = ({ initialValues, handleSubmit, clearApiError, apiErrors, 
    editorValue, adminPermissions, handlePermissions, handleNavigation, handleDescription}: any) => {
    return (
      <Box style={styles.formBox}>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            data-test-id="formikClickEvent"
          >
          {({ errors, touched, values, setFieldValue, handleChange }) => (
            <Form translate={undefined} style={styles.form_main_Box}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <InputLabel style={styles.label} htmlFor="name">
                    {configJSON.roleNameLabel + " "}<span style={styles.role_span}>*</span>
                  </InputLabel>
                  <Field
                    type="text"
                    data-test-id="role-name"
                    name="name"
                    id="name"
                    placeholder="Name"
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                      handleChange(event);
                      clearApiError();
                    }}
                    error={touched.name && (!!errors.name || !!apiErrors.name)}
                    helperText={touched.name && (errors.name ||apiErrors.name)}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                  <InputLabel style={styles.label} htmlFor="description">
                    {configJSON.description + " "}<span style={styles.role_span}>*</span>
                  </InputLabel>
                    <ReactQuill
                                   data-test-id="text-editor"
                                    placeholder="Enter description"
                                    className="role-editor"
                                    modules={modules}
                                    formats={formats}
                                    value={editorValue}
                                    onChange={(value: string) => 
                                      {
                                        handleDescription(value, setFieldValue);
                                      }}
                                    style={{
                                      fontStyle: 'normal',
                                      border: (touched.description && errors.description) ? '1px solid #DC2626' :"",
                                    }}
                                  />
              
                  {touched.description && errors.description && (
                    <p style={styles.error}>{errors.description}</p>
                  )}
                </Grid>
                <Grid item xs={12} style={{ borderRadius: 8, border: "1px solid #E6DEED", marginTop: 90 }}>
                  <Typography style={styles.permissionHeading}>Admin can</Typography>
                  <Box display="flex" alignItems="center">
                    <CustomRadio checked testid="perform-action" /><Typography style={styles.permissionItem}>{configJSON.permissionHead}</Typography>
                  </Box>
                  <List>
                    {Object.keys(adminPermissions).map((permission: string) =>
                      <ListItem key={permission} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemIcon>
                            <CustomRadio checked={values.permissions[permission as keyof PermissionList]} 
                            testid="admin-permissions" handleClick={() => handlePermissions(permission, setFieldValue)} />
                        </ListItemIcon>
                        <PermissionListItemText style={styles.permission}>
                          {configJSON[permission]}
                        </PermissionListItemText>
                    </ListItem>)}
                  </List>
                </Grid>
              </Grid>
              <Box mt={'30px'} style={styles.buttonBox}>
                <Button data-test-id="cancelBtn" type="reset" style={styles.cancelButton} onClick={() => handleNavigation("Rolesandpermissions")}>{configJSON.cancelBTN}</Button>
                <Button type="submit" data-test-id="saveBtn" style={styles.addButton} >{configJSON.saveBTN}</Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    )
}
// Customizable Area End

// Customizable Area Start
const styles: Record<string, React.CSSProperties> = {
  label: {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 500,
    color: "#3D405C",
    fontFamily: "Manrope"
  },

  error: {
    fontFamily: "Manrope",
    fontWeight: 400,
    fontSize: 12,
    color: '#DC2626',
    margin: 0,
    lineHeight: "18px"
  },
  permissionHeading: {
    marginTop: 10, 
    marginLeft: 15,
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Manrope",
    lineHeight: "22px"
  },
  formBox: {
    padding: "24px",
    boxShadow: "rgba(135, 96, 169, 0.16) 0px 8px 32px 0px, rgba(135, 96, 169, 0.13) 0px 4px 8px 0px",
    borderRadius: "8px",
    gap: "24px"
  },
  permissionItem: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Manrope",
    color: "#33354D"
  },
  role_span: {
    color: "#F22A2A"
  },

  buttonBox: {
    display: "flex",
    justifyContent: "flex-end"
  },

  cancelButton: {
    backgroundColor: "transparent",
    color: "#8760A9",
    padding: "10px 16px",
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "16px",
    borderRadius: "4px",
    letterSpacing: "0em",
    textTransform: "initial",
  } as const,

  addButton: {
    padding: "10px 16px",
    color: "#FFF",
    backgroundColor: "#8760A9",
    fontSize: "16px",
    fontFamily: "Manrope",
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: "16px",
    borderRadius: "4px",
    textTransform: "initial",
  } as const,
}
// Customizable Area End
    
export default RoleForm;